@use '../../variables' as *;

.hover-card-controller[data-popper-reference-hidden='true'] {
  opacity: 0;
  pointer-events: none;
}

.hover-card {
  box-shadow: var(--dropdown-shadow);
  background: var(--modal-background-color);
  backdrop-filter: var(--background-filter);
  border: 1px solid var(--modal-border-color);
  border-radius: 8px;
  padding: 16px;
  width: 270px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &--loading {
    position: relative;
    min-height: 100px;
  }

  &__name {
    display: flex;
    gap: 12px;
    text-decoration: none;
    color: inherit;
  }

  &__number {
    font-size: 15px;
    line-height: 22px;
    color: $secondary-text-color;

    strong {
      font-weight: 700;
    }
  }

  &__text-row {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__bio {
    color: $secondary-text-color;
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 2 * 20px;
    overflow: hidden;

    p {
      margin-bottom: 0;
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  &__note {
    &-label {
      color: $dark-text-color;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }

    dd {
      white-space: pre-line;
      color: $secondary-text-color;
      overflow: hidden;
      line-clamp: 3; // Not yet supported in browsers
      display: -webkit-box; // The next 3 properties are needed
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .display-name {
    font-size: 15px;
    line-height: 22px;

    bdi {
      font-weight: 500;
      color: $primary-text-color;
    }

    &__account {
      display: block;
      color: $dark-text-color;
    }
  }

  .account-fields {
    color: $secondary-text-color;
    font-size: 14px;
    line-height: 20px;

    a {
      color: inherit;
      text-decoration: none;

      &:focus,
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    dl {
      display: flex;
      align-items: center;
      gap: 4px;

      dt {
        flex: 0 1 auto;
        color: $dark-text-color;
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      dd {
        flex: 1 1 auto;
        font-weight: 500;
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: end;
      }

      &.verified {
        dd {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 4px;
          overflow: hidden;
          white-space: nowrap;
          color: $valid-value-color;

          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          a {
            font-weight: 500;
          }

          .icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
