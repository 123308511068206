@use '../variables' as *;

.scrollable .account-card {
  margin: 10px;
}

.scrollable .account-card__title__avatar {
  img {
    border: 2px solid var(--background-color);
  }

  .account__avatar {
    border: none;
  }
}

.scrollable .account-card__header {
  img {
    border-radius: 4px;
  }
}

.scrollable .account-card__bio::after {
  background: linear-gradient(to left, var(--background-color), transparent);
}

.filter-form {
  border-bottom: 1px solid var(--background-border-color);

  &__column {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }

  .radio-button {
    display: flex;
  }
}

.radio-button,
.check-box {
  font-size: 14px;
  position: relative;
  display: inline-flex;
  align-items: center;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  gap: 10px;
  color: $secondary-text-color;

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 2px solid $secondary-text-color;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    border-radius: 50%;

    &.checked,
    &.indeterminate {
      border-color: $ui-highlight-color;
    }

    .icon {
      width: 18px;
      height: 18px;
    }
  }
}

.radio-button__input.checked::before {
  position: absolute;
  left: 2px;
  top: 2px;
  content: '';
  display: block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background: $ui-highlight-color;
}

.check-box {
  &__input {
    width: 18px;
    height: 18px;
    border-radius: 2px;

    &.checked,
    &.indeterminate {
      background: $ui-highlight-color;
      color: $white;
    }
  }
}
