@use '../../variables' as *;

// Polyam: Alt-text modal feature
.alttext-modal {
  flex-direction: column;
  max-width: 600px;
  overflow-y: auto;

  &__top,
  &__bottom {
    display: flex;
    gap: 8px;
    padding: 24px;
    flex-direction: column;
    background: var(--modal-background-color);
    backdrop-filter: var(--background-filter);
    border: 1px solid var(--modal-border-color);
  }

  &__top {
    border-radius: 16px 16px 0 0;
    border-bottom: 0;
    gap: 16px;
  }

  &__bottom {
    border-radius: 0 0 16px 16px;
    border-top: 0;
    padding-top: 0;

    @media screen and (max-width: $no-gap-breakpoint) {
      border-radius: 0;
      border-bottom: 0;
      padding-bottom: 32px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
  }

  &__description {
    font-size: 16px;
    line-height: 22px;

    pre {
      white-space: pre-wrap;
    }
  }
}
