@use 'mixins' as *;
@use '../variables' as *;

// Polyam: Split from .account__relationship
.explore__suggestions__card {
  @include follow-unfollow-icons;
}

.explore__suggestions__card {
  padding: 12px 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--background-border-color);

  &:last-child {
    border-bottom: 0;
  }

  &__source {
    padding-inline-start: 60px;
    font-size: 13px;
    line-height: 16px;
    color: $dark-text-color;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__body {
    display: flex;
    gap: 12px;
    align-items: center;

    &__main {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 0;

      &__name-button {
        display: flex;
        align-items: center;
        gap: 8px;

        &__name {
          display: block;
          color: inherit;
          text-decoration: none;
          flex: 1 1 auto;
          min-width: 0;
        }

        .button {
          min-width: 80px;
        }

        .display-name {
          font-size: 15px;
          line-height: 20px;
          color: $secondary-text-color;

          strong {
            font-weight: 700;
          }

          &__account {
            color: $darker-text-color;
            display: block;
          }
        }
      }
    }
  }
}

.explore__search-header {
  justify-content: center;
  align-items: center;
  border: 1px solid var(--background-border-color);
  border-top: 0;
  border-bottom: 0;
  padding: 16px;
  padding-bottom: 8px;

  .search {
    width: 100%;
    margin-bottom: 0;
  }

  .search__input {
    border: 1px solid var(--background-border-color);
    padding: 12px;
    padding-inline-end: 30px;
  }

  .search__popout {
    border: 1px solid var(--background-border-color);
  }

  .search__icon {
    top: 12px;
    inset-inline-end: 12px;
    color: $dark-text-color;
  }
}

.layout-single-column .explore__search-header {
  display: none;

  @media screen and (max-width: $no-gap-breakpoint - 1px) {
    display: flex;
  }
}

.explore__search-results {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $no-gap-breakpoint) {
    border: 1px solid var(--background-border-color);
    border-top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.story {
  display: flex;
  align-items: center;
  color: $primary-text-color;
  padding: 16px;
  border-bottom: 1px solid var(--background-border-color);
  gap: 16px;

  &:last-child {
    border-bottom: 0;
  }

  &__details {
    flex: 1 1 auto;

    &__publisher {
      color: $darker-text-color;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 20px;
    }

    &__title {
      display: block;
      font-size: 19px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 8px;
      text-decoration: none;
      color: $primary-text-color;

      &:hover,
      &:active,
      &:focus {
        color: $highlight-text-color;
      }
    }

    &__shared {
      display: flex;
      align-items: center;
      color: $darker-text-color;
      gap: 8px;
      justify-content: space-between;
      font-size: 14px;
      line-height: 20px;

      & > span {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      &__pill {
        background: var(--surface-variant-background-color);
        border-radius: 4px;
        color: inherit;
        text-decoration: none;
        padding: 4px 12px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }

      &__author-link {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        color: $primary-text-color;
        font-weight: 500;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
          color: $highlight-text-color;
        }
      }
    }

    strong {
      font-weight: 500;
    }
  }

  &__thumbnail {
    flex: 0 0 auto;
    position: relative;
    width: 120px;
    height: 120px;

    .skeleton {
      width: 100%;
      height: 100%;
    }

    img {
      border-radius: 8px;
      display: block;
      margin: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__preview {
      border-radius: 8px;
      display: block;
      margin: 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      z-index: 0;

      &--hidden {
        display: none;
      }
    }
  }

  &.expanded {
    flex-direction: column;

    .story__thumbnail {
      order: 1;
      width: 100%;
      height: auto;
      aspect-ratio: 1.91 / 1;
    }

    .story__details {
      order: 2;
      width: 100%;
      flex: 0 0 auto;
    }
  }
}
