@use '../variables' as *;

.upload-area {
  align-items: center;
  background: rgba($base-overlay-background, 0.8);
  display: flex;
  height: 100vh;
  justify-content: center;
  inset-inline-start: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100vw;
  z-index: 2000;

  * {
    pointer-events: none;
  }
}

.upload-area__drop {
  width: 320px;
  height: 160px;
  display: flex;
  box-sizing: border-box;
  position: relative;
  padding: 8px;
}

.upload-area__background {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: -1;
  border-radius: 4px;
  background: $ui-base-color;
  box-shadow: 0 0 5px rgba($base-shadow-color, 0.2);
}

.upload-area__content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $secondary-text-color;
  font-size: 18px;
  font-weight: 500;
  border: 2px dashed $ui-base-lighter-color;
  border-radius: 4px;
}
