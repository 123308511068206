@use '../variables' as *;
@use '../functions' as *;
@use 'mixins' as *;

.column__wrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}

.columns-area {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  position: relative;

  &.unscrollable {
    overflow-x: hidden;
  }

  &__panels {
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    &__pane {
      height: 100%;
      overflow: hidden;
      pointer-events: none;
      display: flex;
      justify-content: flex-end;
      min-width: 285px;

      &--start {
        justify-content: flex-start;
      }

      &__inner {
        position: fixed;
        width: 285px;
        pointer-events: auto;
        height: 100%;
      }
    }

    &__main {
      box-sizing: border-box;
      width: 100%;
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      contain: inline-size layout paint style;

      @media screen and (min-width: $no-gap-breakpoint) {
        max-width: 600px;
      }
    }
  }
}

$ui-header-height: 55px;
$ui-header-logo-wordmark-width: 99px;

.ui__header {
  display: none;
  box-sizing: border-box;
  height: $ui-header-height;
  position: sticky;
  top: 0;
  z-index: 3;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: var(--background-filter);

  &__logo {
    display: inline-flex;
    padding: 15px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    container: header-logo / inline-size;

    .logo {
      height: $ui-header-height - 30px;
      width: auto;
    }

    .logo--wordmark {
      display: none;
    }

    @container header-logo (min-width: #{$ui-header-logo-wordmark-width}) {
      .logo--wordmark {
        display: block;
      }

      .logo--icon {
        display: none;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    overflow: hidden;
    flex-shrink: 0;

    .button {
      flex: 0 0 auto;
    }

    .button-tertiary {
      flex-shrink: 1;
    }

    .icon {
      width: 19px;
      height: 19px;
    }
  }
}

.tabs-bar__wrapper {
  background: var(--background-color);
  backdrop-filter: var(--background-filter);
  position: sticky;
  top: $ui-header-height;
  z-index: 2;
  padding-top: 0;

  @media screen and (min-width: $no-gap-breakpoint) {
    padding-top: 10px;
    top: 0;
  }
}

.column {
  width: 350px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  > .scrollable {
    border: 1px solid var(--background-border-color);
    border-top: 0;
    border-radius: 0 0 4px 4px;

    &.about,
    &.privacy-policy {
      border-top: 1px solid var(--background-border-color);
      border-radius: 4px;

      @media screen and (max-width: $no-gap-breakpoint) {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
}

.ui {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

// Polyam: Split from .column, .drawer
.column {
  @include column-drawer;
}

// .columns-area--mobile (Moved to single-column.scss by upstream)

.column-link--transparent .icon-with-badge__badge {
  border-color: var(--background-color);
}

.column-title {
  text-align: center;
  padding-bottom: 32px;

  h3 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $darker-text-color;
  }

  @media screen and (width >= 600px) {
    padding: 40px;
  }
}

.column-back-button {
  box-sizing: border-box;
  width: 100%;
  background: transparent;
  border: 1px solid var(--background-border-color);
  border-radius: 4px 4px 0 0;
  color: $highlight-text-color;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 16px;
  line-height: inherit;
  text-align: unset;
  padding: 13px;
  margin: 0;
  z-index: 3;
  outline: 0;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
}

.column-header__back-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: transparent;
  border: 0;
  font-family: inherit;
  color: $highlight-text-color;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  padding: 13px;
  z-index: 3;

  &:hover {
    text-decoration: underline;
  }

  &.compact {
    padding-inline-end: 5px;
    flex: 0 0 auto;
  }
}

.switch-to-advanced {
  color: $light-text-color;
  background-color: $ui-base-color;
  padding: 15px;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 12px;
  font-size: 13px;
  line-height: 18px;

  .switch-to-advanced__toggle {
    color: $ui-button-tertiary-color;
    font-weight: bold;
  }
}

.column-link {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  padding: 13px;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  background: transparent;
  color: $secondary-text-color;
  border-left: 4px solid transparent;

  &:hover,
  &:focus,
  &:active {
    color: $primary-text-color;
  }

  &.active {
    color: $highlight-text-color;
  }

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    border-color: $ui-button-focus-outline-color;
    border-radius: 0;
  }

  &--logo {
    background: transparent;
    padding: 10px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }
  }
}

.column-link__badge {
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
  background: $ui-base-color;
  padding: 4px 8px;
  margin: -6px 10px;
}

.column-subheading {
  background: var(--surface-background-color);
  color: $darker-text-color;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: default;
}

.column-header__wrapper {
  position: relative;
  flex: 0 0 auto;
  z-index: 1;

  &.active {
    box-shadow: 0 1px 0 rgba($highlight-text-color, 0.3);

    &::before {
      display: block;
      content: '';
      position: absolute;
      bottom: -13px;
      inset-inline-start: 0;
      inset-inline-end: 0;
      margin: 0 auto;
      width: 60%;
      pointer-events: none;
      height: 28px;
      z-index: 1;
      background: radial-gradient(
        ellipse,
        rgba($ui-highlight-color, 0.23) 0%,
        rgba($ui-highlight-color, 0) 60%
      );
    }
  }

  .announcements {
    z-index: 1;
    position: relative;
  }
}

.column-header__select-row {
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: var(--background-border-color);
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 8px;

  &__checkbox .check-box {
    display: flex;
  }

  &__select-menu:disabled {
    visibility: hidden;
  }

  &__mode-button {
    margin-left: auto;
    color: $highlight-text-color;
    font-weight: bold;
    font-size: 14px;

    &:hover {
      color: lighten($highlight-text-color, 6%);
    }
  }
}

.column-header {
  display: flex;
  font-size: 16px;
  border: 1px solid var(--background-border-color);
  border-radius: 4px 4px 0 0;
  flex: 0 0 auto;
  cursor: pointer;
  position: relative;
  z-index: 2;
  outline: 0;

  &__title {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
    border: 0;
    padding: 13px;
    padding-inline-end: 0;
    color: inherit;
    background: transparent;
    font: inherit;
    text-align: start;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;

    &:focus-visible {
      outline: $ui-button-icon-focus-outline;
    }
  }

  .column-header__back-button + &__title {
    padding-inline-start: 0;
  }

  .column-header__back-button {
    flex: 1;
    color: $highlight-text-color;

    &.compact {
      flex: 0 0 auto;
      color: $primary-text-color;
    }
  }

  &.active {
    .column-header__icon {
      color: $highlight-text-color;
      text-shadow: 0 0 10px rgba($highlight-text-color, 0.4);
    }
  }

  &:focus,
  &:active {
    outline: 0;
  }

  &__advanced-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    padding-top: 0;

    &:first-child {
      padding-top: 16px;
    }
  }
}

.column-header__buttons {
  height: 48px;
  display: flex;
}

.column-header__links {
  margin-bottom: 14px;
}

.column-header__links .text-btn {
  margin-inline-end: 10px;
}

.column-header__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  color: $darker-text-color;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
  padding: 0 15px;

  &:last-child {
    border-start-end-radius: 4px;
  }

  &:hover {
    color: lighten($darker-text-color, 4%);
  }

  &:focus-visible {
    outline: $ui-button-focus-outline;
  }

  &.active {
    color: $primary-text-color;

    &:hover {
      color: $primary-text-color;
    }

    .icon-sliders {
      transform: rotate(60deg);
    }
  }

  // Polyam: Added focus ring for keyboard navigation
  &:focus {
    text-shadow: 0 0 4px darken($ui-highlight-color, 5%);
  }

  &:disabled {
    color: $dark-text-color;
    cursor: default;
  }
}

.no-reduce-motion .column-header__button .icon-sliders {
  transition: transform 150ms ease-in-out;
}

.column-header__notif-cleaning-buttons {
  display: flex;
  align-items: stretch;
  justify-content: space-around;

  .column-header__button {
    background: transparent;
    text-align: center;
    padding: 10px 5px;
    font-size: 14px;
    display: block; // Polyam: Fix display of buttons
  }

  b {
    font-weight: bold;
  }
}

.layout-single-column .column-header__notif-cleaning-buttons {
  @media screen and (min-width: $no-gap-breakpoint) {
    b,
    i {
      margin-inline-end: 5px;
    }

    br {
      display: none;
    }

    button {
      padding: 15px 5px;
    }
  }
}

// The notifs drawer with no padding to have more space for the buttons
.column-header__collapsible-inner.nopad-drawer {
  padding: 0;
}

.column-header__collapsible {
  max-height: 70vh;
  overflow: hidden;
  overflow-y: auto;
  color: $darker-text-color;
  transition:
    max-height 150ms ease-in-out,
    opacity 300ms linear;
  opacity: 1;
  z-index: 1;
  position: relative;
  border-left: 1px solid var(--background-border-color);
  border-right: 1px solid var(--background-border-color);
  border-bottom: 1px solid var(--background-border-color);

  @media screen and (max-width: $no-gap-breakpoint) {
    border-left: 0;
    border-right: 0;
  }

  &.collapsed {
    max-height: 0;
    opacity: 0.5;
    border-bottom: 0;
  }

  &.animating {
    overflow-y: hidden;
  }

  hr {
    height: 0;
    background: transparent;
    border: 0;
    border-top: 1px solid var(--background-border-color);
    margin: 10px 0;
  }

  // notif cleaning drawer
  &.ncd {
    transition: none;

    &.collapsed {
      max-height: 0;
      opacity: 0.7;
    }
  }
}

.column-header__collapsible-inner {
  border-top: 0;
}

.column-header__setting-btn {
  &:hover,
  &:focus {
    color: $darker-text-color;
    text-decoration: underline;
  }

  // Polyam: Fix icon size of arrows in column settings
  .icon {
    width: 13px;
    height: 13px;
  }
}

.column-header__collapsible__extra + .column-header__setting-btn {
  padding-top: 5px;
}

.column-header__permission-btn {
  display: inline;
  font-weight: inherit;
  text-decoration: underline;
}

.column-header__setting-arrows {
  display: flex;
  align-items: center;
}

.column-header__issue-btn {
  color: $warning-red;

  &:hover {
    color: $error-red;
    text-decoration: underline;
  }
}

.limited-account-hint {
  p {
    color: $secondary-text-color;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.empty-column-indicator,
.follow_requests-unlocked_explanation {
  color: $dark-text-color;
  text-align: center;
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  cursor: default;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;

  & > span {
    max-width: 500px;
  }

  a {
    color: $highlight-text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.empty-column-indicator {
  &__arrow {
    position: absolute;
    top: 50%;
    inset-inline-start: 50%;
    pointer-events: none;
    transform: translate(100%, -100%) rotate(12deg);
    transform-origin: center;
  }
}

.follow_requests-unlocked_explanation {
  background: var(--surface-background-color);
  border-bottom: 1px solid var(--background-border-color);
  contain: initial;
  flex-grow: 0;
}

.error-column {
  padding: 20px;
  border: 1px solid var(--background-border-color);
  border-radius: 4px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;

  &__image {
    width: 70%;
    max-width: 350px;
    margin-top: -50px;
  }

  &__message {
    text-align: center;
    color: $darker-text-color;
    font-size: 15px;
    line-height: 22px;

    h1 {
      font-size: 28px;
      line-height: 33px;
      font-weight: 700;
      margin-bottom: 15px;
      color: $primary-text-color;
    }

    p {
      max-width: 48ch;
    }

    &__actions {
      margin-top: 30px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }
  }
}

// glitch: optional wide mode
.wide .columns-area:not(.columns-area--mobile) {
  .column {
    flex: auto;
    min-width: 330px;
    max-width: 400px;
  }

  .drawer {
    min-width: 300px;
    max-width: 400px;
    flex: 1 1 200px;
  }
}

.column-inline-form {
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  border: 1px solid var(--background-border-color);
  border-top: 0;

  // Polyam: Fix awkward input width
  input {
    width: 100%;
  }

  label {
    flex: 1 1 auto;

    input {
      width: 100%;
    }
  }

  @media screen and (max-width: $no-gap-breakpoint) {
    border-left: 0;
    border-right: 0;
  }
}

.column-search-header {
  display: flex;
  gap: 12px;
  align-items: center;
  border: 1px solid var(--background-border-color);
  border-top: 0;
  border-bottom: 0;
  padding: 16px;
  padding-bottom: 8px;

  input {
    background: var(--input-background-color);
    border: 1px solid var(--background-border-color);
    color: var(--on-input-color);
    padding: 12px;
    font-size: 16px;
    line-height: normal;
    border-radius: 4px;
    display: block;
    flex: 1 1 auto;

    &::placeholder {
      color: var(--input-placeholder-color);
      opacity: 1;
    }

    &:focus {
      outline: 0;
    }
  }
}

.column-footer {
  padding: 16px;
}
