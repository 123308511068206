@use '../variables' as *;
@use '../functions' as *;
@use 'mixins' as *;

.conversation {
  display: flex;
  border-bottom: 1px solid var(--background-border-color);
  padding: 5px;
  padding-bottom: 0;

  &:focus {
    background: lighten($ui-base-color, 2%);
    outline: 0;
  }

  &__avatar {
    flex: 0 0 auto;
    padding: 10px;
    padding-top: 12px;
    position: relative;
    cursor: pointer;
  }

  &__unread {
    display: inline-block;
    background: $highlight-text-color;
    border-radius: 50%;
    width: 0.625rem;
    height: 0.625rem;
    margin: -0.1ex 0.15em 0.1ex;
  }

  &__content {
    flex: 1 1 auto;
    padding: 10px 5px;
    padding-inline-end: 15px;
    overflow: hidden;

    &__info {
      overflow: hidden;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &__relative-time {
      font-size: 15px;
      color: $darker-text-color;
      padding-inline-start: 15px;
    }

    &__names {
      color: $darker-text-color;
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 4px;
      flex-basis: 90px;
      flex-grow: 1;

      a {
        color: $primary-text-color;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
      }
    }

    .status__content {
      margin: 0;
    }
  }
}

// Polyam: Split from .notification, .status__wrapper, .conversation
.conversation {
  @include notification-status-wrapper;
}
