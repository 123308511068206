@use '../variables' as *;
@use '../functions' as *;
@use 'mixins' as *;

.navigation-bar {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: default;
  gap: 10px;

  .column > & {
    padding: 15px;
  }

  .account {
    border-bottom: 0;
    padding: 0;
    flex: 1 1 auto;
    min-width: 0;

    &__display-name {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      font-weight: 500;

      .display-name__account {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }
  }

  .icon-button {
    padding: 8px;
    color: $secondary-text-color;
  }

  // Polyam: size 18px instead of 24px
  .icon-button .icon {
    width: 18px;
    height: 18px;
  }
}

// This class exists twice in vanilla, merged into one
.drawer {
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

// Polyam: kept old padding compared to upstream, because it looks nicer
.drawer__tab {
  display: flex;
  flex: 1 1 auto;
  padding: 15px 5px; // Polyam: Adjusted to keep same size as column headers
  color: $darker-text-color;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

// Polyam: Split from .columns, .drawer
.drawer {
  @include column-drawer;
}

.drawer__pager {
  box-sizing: border-box;
  padding: 0;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--background-border-color);
}

.drawer__inner {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  background: var(--background-color);
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.drawer__inner__mastodon {
  background: var(--background-color)
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.80078 31.757813" width="234.80078" height="31.757812"><path d="M19.599609 0c-1.05 0-2.10039.375-2.90039 1.125L0 16.925781v14.832031h234.80078V17.025391l-16.5-15.900391c-1.6-1.5-4.20078-1.5-5.80078 0l-13.80078 13.099609c-1.6 1.5-4.19883 1.5-5.79883 0L179.09961 1.125c-1.6-1.5-4.19883-1.5-5.79883 0L159.5 14.224609c-1.6 1.5-4.20078 1.5-5.80078 0L139.90039 1.125c-1.6-1.5-4.20078-1.5-5.80078 0l-13.79883 13.099609c-1.6 1.5-4.20078 1.5-5.80078 0L100.69922 1.125c-1.600001-1.5-4.198829-1.5-5.798829 0l-13.59961 13.099609c-1.6 1.5-4.200781 1.5-5.800781 0L61.699219 1.125c-1.6-1.5-4.198828-1.5-5.798828 0L42.099609 14.224609c-1.6 1.5-4.198828 1.5-5.798828 0L22.5 1.125C21.7.375 20.649609 0 19.599609 0z" fill="#{hex-color(darken($ui-base-color, 4%))}"/></svg>')
    no-repeat bottom / 100% auto;
  flex: 1;
  min-height: 47px;
  display: none;

  > img {
    display: block;
    object-fit: contain;
    object-position: bottom left;
    width: 85%;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }

  @media screen and (height >= 640px) {
    display: block;
  }
}

.drawer__header {
  flex: 0 0 auto;
  font-size: 16px;
  border: 1px solid var(--background-border-color);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  overflow: hidden;

  a:hover,
  a:focus,
  a:active {
    color: $primary-text-color;
  }
}

// .drawer (has been merged with first)

.drawer__backdrop {
  cursor: pointer;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background: rgba($base-overlay-background, 0.5);
}

.drawer--results {
  overflow-x: hidden;
  overflow-y: scroll;
}
