@use '../variables' as *;
@use '../mixins' as *;

.search {
  margin-bottom: 32px;
  position: relative;

  .layout-multiple-columns & {
    margin-bottom: 10px;
  }

  &__popout {
    box-sizing: border-box;
    display: none;
    position: absolute;
    inset-inline-start: 0;
    margin-top: -2px;
    width: 100%;
    background: var(--input-background-color);
    border: 1px solid var(--background-border-color);
    border-radius: 0 0 4px 4px;
    box-shadow: var(--dropdown-shadow);
    z-index: 99;
    font-size: 13px;
    padding: 15px 5px;

    h4 {
      text-transform: uppercase;
      color: $darker-text-color;
      font-weight: 500;
      padding: 0 10px;
      margin-bottom: 10px;
    }

    .icon-button {
      padding: 0;
      color: $darker-text-color;
    }

    .icon {
      width: 15px;
      height: 15px;
    }

    &__menu {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &__message {
        color: $darker-text-color;
        padding: 0 10px;
      }

      &__item {
        display: block;
        box-sizing: border-box;
        width: 100%;
        border: 0;
        font: inherit;
        background: transparent;
        color: $darker-text-color;
        padding: 10px;
        cursor: pointer;
        border-radius: 4px;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &--flex {
          display: flex;
          justify-content: space-between;
        }

        .icon-button {
          transition: none;
        }

        &:hover,
        &:focus,
        &:active,
        &.selected {
          background: $ui-highlight-color;
          color: $primary-text-color;

          .icon-button {
            color: $primary-text-color;
          }
        }

        mark {
          background: transparent;
          font-weight: 700;
          color: $primary-text-color;
        }

        span {
          overflow: inherit;
          text-overflow: inherit;
        }
      }
    }
  }

  &.active {
    .search__input {
      border-radius: 4px 4px 0 0;
    }

    .search__popout {
      display: block;
    }
  }
}

.search__input {
  @include search-input;

  display: block;
  padding: 12px 16px;
  padding-inline-start: 16px + 15px + 8px;
  line-height: normal;

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }
}

.search__icon {
  background: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  top: 12px + 2px;
  cursor: default;
  pointer-events: none;
  margin-inline-start: 16px - 2px;
  width: 20px;
  height: 20px;

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus {
    outline: 0 !important;
  }

  .icon {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    opacity: 0;
    transition: all 100ms linear;
    transition-property: transform, opacity;
    width: 18px; // Polyam: 18px instead of 20px
    height: 18px; // Polyam: 18px instead of 20px
    color: $darker-text-color;

    &.active {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .icon-search {
    transform: rotate(90deg);

    &.active {
      pointer-events: none;
      transform: rotate(0deg);
    }
  }

  .icon-times-circle {
    transform: rotate(0deg);
    cursor: pointer;

    &.active {
      transform: rotate(90deg);
    }
  }
}

.search-results__section {
  border-bottom: 1px solid var(--background-border-color);

  &:last-child {
    border-bottom: 0;
  }

  &__header {
    border-bottom: 1px solid var(--background-border-color);
    background: var(--surface-background-color);
    padding: 15px;
    font-weight: 500;
    font-size: 14px;
    color: $darker-text-color;
    display: flex;
    justify-content: space-between;

    h3 {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    button {
      color: $highlight-text-color;
      padding: 0;
      border: 0;
      background: 0;
      font: inherit;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .account:last-child,
  & > div:last-child .status {
    border-bottom: 0;
  }
}

.search-results__info {
  padding: 20px;
  color: $darker-text-color;
  text-align: center;
}
