@use '../variables' as *;

.dismissable-banner,
.warning-banner {
  position: relative;
  margin: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  border: 1px solid $highlight-text-color;
  background: rgba($highlight-text-color, 0.15);
  overflow: hidden;
  flex-shrink: 0;

  &__background-image {
    width: 125%;
    position: absolute;
    bottom: -25%;
    inset-inline-end: -25%;
    z-index: -1;
    opacity: 0.15;
    mix-blend-mode: luminosity;
  }

  &__message {
    flex: 1 1 auto;
    padding: 15px;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: $primary-text-color;

    p {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      color: $highlight-text-color;
      font-size: 22px;
      line-height: 33px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      &__wrapper {
        display: flex;
        margin-top: 30px;
      }

      .button {
        display: block;
        flex-grow: 1;
      }
    }

    .button-tertiary {
      background: rgba($ui-base-color, 0.15);
      backdrop-filter: blur(8px);
    }
  }

  &__action {
    float: right;
    padding: 15px 10px;

    .icon-button {
      color: $highlight-text-color;
    }
  }
}

.warning-banner {
  border: 1px solid $warning-red;
  background: rgba($warning-red, 0.15);

  &__message {
    h1 {
      color: $warning-red;
    }

    a {
      color: $primary-text-color;
    }
  }
}
