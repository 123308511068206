@use '../variables' as *;

.react-swipeable-view-container {
  &,
  .columns-area,
  .column {
    height: 100%;
  }
}

.react-swipeable-view-container > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 10px;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: rgba($base-overlay-background, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 32px;
  height: 20px;
  padding: 0;
  border-radius: 10px;
  background-color: #626982; // Why hardcode this?
}

.react-toggle--focus {
  outline: $ui-button-focus-outline;
}

.react-toggle--checked .react-toggle-track {
  background-color: $ui-highlight-color;
}

.react-toggle-track-check,
.react-toggle-track-x {
  display: none;
}

.react-toggle-thumb {
  position: absolute;
  top: 2px;
  inset-inline-start: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $primary-text-color;
  box-sizing: border-box;
  transition: all 0.25s ease;
  transition-property: border-color, left;
}

.react-toggle--checked .react-toggle-thumb {
  inset-inline-start: 32px - 16px - 2px;
  border-color: $ui-highlight-color;
}
