@use '../../variables' as *;
@use '../../functions' as *;
@use '../../mixins' as *;

.modal-container--preloader {
  background: lighten($ui-base-color, 8%);
}

.modal-root__container .privacy-dropdown {
  flex-grow: 0;
}

.modal-root__container .privacy-dropdown__dropdown {
  pointer-events: auto;
  z-index: 9999;
}

.modal-root {
  position: relative;
  z-index: 9998;
}

.modal-root__overlay {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  opacity: 0.9;
  background: $base-overlay-background;
  transition: background 0.5s;
}

.modal-root__container {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: space-around;
  z-index: 9999;
  pointer-events: none;
  user-select: none;
  overscroll-behavior: none;
}

.modal-root__modal {
  pointer-events: auto;
  user-select: text;
  display: flex;
  max-width: 100vw;

  @media screen and (width <= $mobile-breakpoint) {
    margin-top: auto;
  }
}

.video-modal .video-player {
  max-height: 80vh;
  max-width: 100vw;
}

.audio-modal__container {
  width: 50vw;
}

.media-modal {
  width: 100%;
  height: 100%;
  position: relative;

  &__buttons {
    position: absolute;
    inset-inline-end: 8px;
    top: 8px;
    z-index: 100;
    display: flex;
    gap: 8px;
    align-items: center;

    .icon-button {
      color: rgba($white, 0.7);
      padding: 8px;

      .icon {
        width: 24px;
        height: 24px;
        filter: var(--overlay-icon-shadow);
      }

      &:hover,
      &:focus,
      &:active {
        color: $white;
        background-color: rgba($white, 0.15);
      }

      &:focus {
        background-color: rgba($white, 0.3);
      }
    }
  }
}

.media-modal__closer {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
}

.media-modal__navigation {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  will-change: opacity;

  * {
    pointer-events: auto;
  }

  &.media-modal__navigation--hidden {
    opacity: 0;

    * {
      pointer-events: none;
    }
  }
}

.media-modal__nav {
  background: transparent;
  box-sizing: border-box;
  border: 0;
  color: rgba($white, 0.7);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 24px;
  height: 20vmax;
  margin: auto 0;
  padding: 30px 15px;
  position: absolute;
  top: 0;
  bottom: 0;

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }
}

.media-modal__nav--left {
  inset-inline-start: 0;
}

.media-modal__nav--right {
  inset-inline-end: 0;
}

.media-modal__overlay {
  max-width: 600px;
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  margin: 0 auto;

  .picture-in-picture__footer {
    border-radius: 0;
    background: transparent;
    padding: 16px;

    .icon-button {
      color: $white;

      .icon {
        filter: var(--overlay-icon-shadow);
      }

      &:hover,
      &:focus,
      &:active {
        color: $white;
        background-color: rgba($white, 0.15);
      }

      &:focus {
        background-color: rgba($white, 0.3);
      }

      &.active {
        color: $highlight-text-color;

        &:hover,
        &:focus,
        &:active {
          background: rgba($highlight-text-color, 0.15);
        }

        &:focus {
          background: rgba($highlight-text-color, 0.3);
        }
      }

      &.star-icon.active {
        color: $gold-star;

        &:hover,
        &:focus,
        &:active {
          background: rgba($gold-star, 0.15);
        }

        &:focus {
          background: rgba($gold-star, 0.3);
        }
      }

      &.disabled {
        color: $white;
        background-color: transparent;
        cursor: default;
        opacity: 0.4;
      }
    }
  }
}

.media-modal__pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.media-modal__page-dot {
  flex: 0 0 auto;
  background-color: $white;
  filter: var(--overlay-icon-shadow);
  opacity: 0.4;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin: 0 4px;
  padding: 0;
  border: 0;
  font-size: 0;
  transition: opacity 0.2s ease-in-out;

  &.active {
    opacity: 1;
  }

  &:focus {
    outline: 0;
    background-color: $highlight-text-color;
  }
}

.modal-placeholder {
  width: 588px;
  min-height: 478px;
  flex-direction: column;
  background: var(--modal-background-color);
  backdrop-filter: var(--background-filter);
  border: 1px solid var(--modal-border-color);
  border-radius: 16px;

  &__error {
    padding: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__image {
      width: 70%;
      max-width: 350px;
    }

    &__message {
      text-align: center;
      text-wrap: balance;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;

      &__actions {
        margin-top: 24px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.safety-action-modal {
  width: 600px;
  flex-direction: column;

  &__top,
  &__bottom {
    display: flex;
    gap: 8px;
    padding: 24px;
    flex-direction: column;
    background: var(--modal-background-color);
    backdrop-filter: var(--background-filter);
    border: 1px solid var(--modal-border-color);
  }

  &__top {
    border-radius: 16px 16px 0 0;
    border-bottom: 0;
    gap: 16px;
  }

  &__bottom {
    border-radius: 0 0 16px 16px;
    border-top: 0;

    @media screen and (max-width: $mobile-breakpoint) {
      border-radius: 0;
      border-bottom: 0;
      padding-bottom: 32px;
    }
  }

  &__header {
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: $darker-text-color;

    &__icon {
      border-radius: 64px;
      background: $ui-highlight-color;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      flex-shrink: 0;

      .icon {
        width: 24px;
        height: 24px;
      }
    }

    h1 {
      font-size: 22px;
      line-height: 28px;
      color: $primary-text-color;
    }
  }

  &__confirmation {
    font-size: 14px;
    line-height: 20px;
    color: $darker-text-color;

    h1 {
      font-size: 16px;
      line-height: 24px;
      color: $primary-text-color;
      font-weight: 500;
      margin-bottom: 8px;
    }

    strong {
      font-weight: 700;
      color: $primary-text-color;
    }
  }

  &__status {
    border: 1px solid var(--modal-border-color);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;

    &__account {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 8px;
      color: $dark-text-color;

      bdi {
        color: inherit;
      }
    }

    &__content {
      display: -webkit-box;
      font-size: 15px;
      line-height: 22px;
      color: $dark-text-color;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      max-height: 4 * 22px;
      overflow: hidden;

      p,
      a {
        color: inherit;
      }
    }

    .reply-indicator__attachments {
      margin-top: 0;
      font-size: 15px;
      line-height: 22px;
      color: $dark-text-color;
    }
  }

  &__bullet-points {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;

    & > div {
      display: flex;
      gap: 16px;
      align-items: center;

      strong {
        font-weight: 700;
      }
    }

    &--deemphasized {
      color: $secondary-text-color;
    }

    &__icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      // Polyam: Smaller icon size
      .icon {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__field-group {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      gap: 16px;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      height: 32px;
      padding: 0 12px;
    }
  }

  &__caveats {
    font-size: 14px;
    padding: 0 12px;

    strong {
      font-weight: 500;
    }
  }

  &__bottom {
    padding-top: 0;

    &__collapsible {
      display: none;
      flex-direction: column;
      gap: 16px;
    }

    &.active {
      background: var(--modal-background-variant-color);
      padding-top: 24px;

      .safety-action-modal__bottom__collapsible {
        display: flex;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;

    &__hint {
      font-size: 14px;
      line-height: 20px;
      color: $dark-text-color;
    }

    .link-button {
      padding: 10px 12px;
      font-weight: 600;
    }
  }
}

.dialog-modal {
  width: 588px;
  max-height: 80vh;
  flex-direction: column;
  background: var(--modal-background-color);
  backdrop-filter: var(--background-filter);
  border: 1px solid var(--modal-border-color);
  border-radius: 16px;

  &__header {
    box-sizing: border-box;
    border-bottom: 1px solid var(--modal-border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 12px 24px;
    min-height: 61px;

    &__title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 0.15px;
    }
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    overflow-y: auto;

    &__form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
    }

    &__preview {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      justify-content: center;
      padding: 24px;
      background: #000;

      img {
        display: block;
      }

      img,
      .gifv video {
        outline: 1px solid var(--media-outline-color);
        outline-offset: -1px;
        border-radius: 8px;
      }

      img,
      .gifv video,
      .video-player,
      .audio-player {
        max-width: 360px;
        max-height: 45vh;
      }
    }
  }

  &__popout {
    background: var(--dropdown-background-color);
    backdrop-filter: var(--background-filter);
    border: 1px solid var(--dropdown-border-color);
    box-shadow: var(--dropdown-shadow);
    max-width: 320px;
    padding: 16px;
    border-radius: 8px;
    z-index: 9999 !important;
    font-size: 14px;
    line-height: 20px;
    color: $darker-text-color;
  }

  .copy-paste-text {
    margin-bottom: 0;
  }
}

.hotkey-combination {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  kbd {
    padding: 3px 5px;
    border: 1px solid var(--background-border-color);
    border-radius: 4px;
  }
}

.doodle-modal,
.boost-modal,
.report-modal,
.actions-modal,
.compare-history-modal {
  background: var(--background-color);
  color: $primary-text-color;
  border-radius: 4px;
  border: 1px solid var(--background-border-color);
  overflow: hidden;
  max-width: 90vw;
  width: 480px;
  position: relative;
  flex-direction: column;

  @media screen and (max-width: $no-columns-breakpoint) {
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
  }
}

.boost-modal__container {
  overflow-y: auto;
  padding: 10px;

  .status {
    user-select: text;
    border-bottom: 0;
  }
}

.doodle-modal__action-bar,
.boost-modal__action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $ui-secondary-color;
  padding: 10px;

  & > div {
    flex: 1 1 auto;
    text-align: end;
    color: $lighter-text-color;
    padding-inline-end: 10px;
  }

  // Polyam: top instead of middle to align FA icons
  .icon {
    vertical-align: top;
  }

  .button {
    flex: 0 0 auto;
  }
}

.report-modal {
  width: 90vw;
  max-width: 700px;
  border: 1px solid var(--background-border-color);
}

.report-dialog-modal {
  max-width: 90vw;
  width: 480px;
  height: 80vh;
  background: var(--background-color);
  color: $primary-text-color;
  border-radius: 4px;
  border: 1px solid var(--background-border-color);
  overflow: hidden;
  position: relative;
  flex-direction: column;
  display: flex;

  &__container {
    box-sizing: border-box;
    border-top: 1px solid var(--background-border-color);
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow: auto;
  }

  &__title {
    font-size: 28px;
    line-height: 33px;
    font-weight: 700;
    margin-bottom: 15px;

    @media screen and (height <= 800px) {
      font-size: 22px;
    }
  }

  &__subtitle {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 4px;
  }

  &__lead {
    font-size: 17px;
    line-height: 22px;
    color: $secondary-text-color;
    margin-bottom: 30px;

    a {
      text-decoration: none;
      color: $inverted-text-color;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__actions {
    margin-top: 30px;
    display: flex;

    .button {
      flex: 1 1 auto;
    }
  }

  &__statuses {
    flex-grow: 1;
    min-height: 0;
    overflow: auto;
  }

  .status__content a {
    color: $highlight-text-color;
  }

  .status__content,
  .status__content p {
    color: $primary-text-color;
  }

  .dialog-option {
    align-items: center;
    gap: 12px;
  }

  .dialog-option .poll__input {
    border-color: $darker-text-color;
    color: $ui-secondary-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 15px;
      height: 15px;
    }

    &:active,
    &:focus,
    &:hover {
      border-color: $valid-value-color;
      border-width: 4px;
    }

    &.active {
      border-color: $valid-value-color;
      background: $valid-value-color;
    }
  }

  .poll__option.dialog-option {
    padding: 15px 0;
    flex: 0 0 auto;
    border-bottom: 1px solid var(--background-border-color);

    &:last-child {
      border-bottom: 0;
    }

    & > .poll__option__text {
      font-size: 13px;
      color: $secondary-text-color;

      strong {
        font-size: 17px;
        font-weight: 500;
        line-height: 22px;
        color: $primary-text-color;
        display: block;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .flex-spacer {
    background: transparent;
  }

  &__textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    color: $primary-text-color;
    background: $ui-base-color;
    padding: 10px;
    font-family: inherit;
    font-size: 17px;
    line-height: 22px;
    resize: vertical;
    border: 0;
    border: 1px solid var(--background-border-color);
    outline: 0;
    border-radius: 4px;
    margin: 20px 0;

    &:focus {
      outline: 0;
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 8px;

    & > span {
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .button.button-secondary {
    border-color: $ui-button-destructive-background-color;
    color: $ui-button-destructive-background-color;
    flex: 0 0 auto;

    &:hover,
    &:focus,
    &:active {
      background: $ui-button-destructive-background-color;
      border-color: $ui-button-destructive-background-color;
      color: $white;
    }
  }

  hr {
    border: 0;
    background: transparent;
    margin: 15px 0;
  }

  .emoji-mart-search {
    padding-inline-end: 10px;
  }

  .emoji-mart-search-icon {
    inset-inline-end: 10px + 5px;
  }
}

.report-modal__container {
  display: flex;
  border-top: 1px solid var(--background-border-color);

  @media screen and (width <= 480px) {
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

.report-modal__statuses,
.report-modal__comment {
  box-sizing: border-box;
  width: 50%;
  min-width: 50%;

  @media screen and (width <= 480px) {
    width: 100%;
  }
}

.report-modal__statuses,
.focal-point-modal__content {
  flex: 1 1 auto;
  min-height: 20vh;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  .status__content a {
    color: $highlight-text-color;
  }

  .status__content,
  .status__content p {
    color: $inverted-text-color;
  }

  @media screen and (width <= 480px) {
    max-height: 10vh;
  }
}

.focal-point-modal__content {
  @media screen and (width <= 480px) {
    max-height: 40vh;
  }
}

.setting-divider {
  background: transparent;
  border: 0;
  margin: 0;
  width: 100%;
  height: 1px;
  margin-bottom: 29px;
}

.actions-modal {
  max-height: 80vh;
  max-width: 80vw;

  .actions-modal__item-label {
    font-weight: 500;
  }

  ul {
    overflow-y: auto;
    flex-shrink: 0;
    max-height: 80vh;

    &.with-status {
      max-height: calc(80vh - 75px);
    }

    li:empty {
      margin: 0;
    }

    li:not(:empty) {
      a {
        color: $primary-text-color;
        display: flex;
        padding: 12px 16px;
        font-size: 15px;
        align-items: center;
        text-decoration: none;

        &,
        button {
          transition: none;
        }

        &.active,
        &:hover,
        &:active,
        &:focus {
          &,
          button {
            background: $ui-highlight-color;
            color: $primary-text-color;
          }
        }

        button:first-child {
          margin-inline-end: 10px;
        }
      }
    }
  }
}

// TODO
.confirmation-modal__do_not_ask_again {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  padding-bottom: 10px;
  font-size: 14px;

  label,
  input {
    vertical-align: middle;
  }
}

.report-modal__target {
  padding: 30px;
  font-size: 16px;

  strong {
    font-weight: 500;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }

  select {
    appearance: none;
    box-sizing: border-box;
    font-size: 14px;
    color: $inverted-text-color;
    display: inline-block;
    width: auto;
    outline: 0;
    font-family: inherit;
    background: $simple-background-color
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color(darken($simple-background-color, 14%))}'/></svg>")
      no-repeat right 8px center / auto 16px;
    border: 1px solid darken($simple-background-color, 14%);
    border-radius: 4px;
    padding: 6px 10px;
    padding-inline-end: 30px;
  }
}

.report-modal__target {
  text-align: center;
}

.report-modal__target {
  padding: 15px;

  .report-modal__close {
    position: absolute;
    top: 10px;
    inset-inline-end: 10px;
  }
}

.compare-history-modal {
  .report-modal__target {
    border-bottom: 1px solid var(--background-border-color);
  }

  &__container {
    padding: 30px;
    pointer-events: all;
    overflow-y: auto;
  }

  .status__content {
    color: $secondary-text-color;
    font-size: 19px;
    line-height: 24px;

    .emojione {
      width: 24px;
      height: 24px;
      margin: -1px 0 0;
    }

    a {
      color: $highlight-text-color;
    }

    hr {
      height: 0.25rem;
      padding: 0;
      background-color: $ui-secondary-color;
      border: 0;
      margin: 20px 0;
    }
  }

  .media-gallery,
  .audio-player,
  .video-player {
    margin-top: 15px;
  }
}

// glitch: deprecated settings modal
img.modal-warning {
  display: block;
  margin: auto;
  margin-bottom: 15px;
  width: 60px;
}

.focal-point {
  position: relative;
  cursor: grab;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &.dragging {
    cursor: grabbing;
  }

  &__reticle {
    position: absolute;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 9999em rgba($base-shadow-color, 0.35);
    pointer-events: none;
  }
}

.safety-action-modal,
.interaction-modal {
  max-width: 100vw;
  width: 600px;
  overflow-y: auto;
}

.interaction-modal {
  overflow: visible;
  position: relative;
  display: block;
  border-radius: 16px;
  background: var(--modal-background-color);
  backdrop-filter: var(--background-filter);
  border: 1px solid var(--modal-border-color);
  padding: 24px;
  box-sizing: border-box;

  @media screen and (max-width: $mobile-breakpoint) {
    border-radius: 16px 16px 0 0;
    border-bottom: 0;
    padding-bottom: 32px;
  }

  h3 {
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  p {
    text-align: center;
    font-size: 17px;
    line-height: 22px;
    color: $darker-text-color;

    strong {
      color: $primary-text-color;
      font-weight: 700;
    }
  }

  p.hint {
    margin-bottom: 14px;
    font-size: 14px;
  }

  &__icon {
    color: $highlight-text-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__lead {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 15px;
    }
  }

  &__login {
    position: relative;
    margin-bottom: 20px;

    &__input {
      @include search-input;

      border: 1px solid var(--background-border-color);
      padding: 4px 6px;
      color: $primary-text-color;
      font-size: 16px;
      line-height: 18px;
      display: flex;
      align-items: center;

      input {
        background: transparent;
        color: inherit;
        font: inherit;
        border: 0;
        padding: 15px - 4px 15px - 6px;
        flex: 1 1 auto;
        min-width: 0;

        &::placeholder {
          color: lighten($darker-text-color, 4%);
        }

        &:focus {
          outline: 0;
        }
      }

      .button {
        flex: 0 0 auto;
      }
    }

    .search__popout {
      margin-top: -1px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 1px solid var(--background-border-color);
    }

    &.invalid &__input {
      border-color: $error-red;
    }

    &.expanded .search__popout {
      display: block;
    }

    &.expanded &__input {
      border-radius: 4px 4px 0 0;
    }
  }

  &__choices {
    display: flex;
    gap: 40px;

    &__choice {
      flex: 1;
      box-sizing: border-box;

      h3 {
        margin-bottom: 20px;
      }

      p {
        color: $darker-text-color;
        margin-bottom: 20px;
        font-size: 15px;
      }

      .button {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media screen and (max-width: $no-gap-breakpoint - 1px) {
    &__choices {
      flex-direction: column;

      &__choice {
        margin-top: 40px;
      }
    }
  }

  .link-button {
    font-size: inherit;
    display: inline;
  }
}
