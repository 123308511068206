@use '../variables' as *;
@use '../functions' as *;

@keyframes circular-progress {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

@keyframes simple-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spring-rotate-in {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-484.8deg);
  }

  60% {
    transform: rotate(-316.7deg);
  }

  90% {
    transform: rotate(-375deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spring-rotate-out {
  0% {
    transform: rotate(-360deg);
  }

  30% {
    transform: rotate(124.8deg);
  }

  60% {
    transform: rotate(-43.27deg);
  }

  90% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes spring-flip-in {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-242.4deg);
  }

  60% {
    transform: rotate(-158.35deg);
  }

  90% {
    transform: rotate(-187.5deg);
  }

  100% {
    transform: rotate(-180deg);
  }
}

@keyframes spring-flip-out {
  0% {
    transform: rotate(-180deg);
  }

  30% {
    transform: rotate(62.4deg);
  }

  60% {
    transform: rotate(-21.635deg);
  }

  90% {
    transform: rotate(7.5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }

  30% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.circular-progress {
  color: lighten($ui-base-color, 26%);
  animation: 1.4s linear 0s infinite normal none running simple-rotate;

  circle {
    stroke: currentColor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    animation: circular-progress 1.4s ease-in-out infinite;
  }
}

.no-reduce-motion .pulse-loading {
  transform-origin: center center;
  animation: heartbeat 1.5s ease-in-out infinite both;
}
