@use '../../variables' as *;

.upload-progress {
  color: $darker-text-color;
  overflow: hidden;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0 12px;

  .icon {
    width: 18px;
    height: 18px;
    color: $ui-highlight-color;
  }

  span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
  }
}

.upload-progress__message {
  flex: 1 1 auto;
}

.upload-progress__backdrop {
  width: 100%;
  height: 6px;
  border-radius: 6px;
  background: var(--background-color);
  position: relative;
  margin-top: 5px;
}

.upload-progress__tracker {
  position: absolute;
  inset-inline-start: 0;
  top: 0;
  height: 6px;
  background: $ui-highlight-color;
  border-radius: 6px;
}
