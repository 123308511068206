@use 'variables' as *;
@use 'functions' as *;

%emoji-color-inversion {
  filter: invert(1);
}

.emojione {
  @each $emoji in $emojis-requiring-inversion {
    &[title=':#{$emoji}:'] {
      @extend %emoji-color-inversion;
    }
  }
}

// Polyam: Kept from upstream -- Upstream uses different icons
// Displays a checkmark on active UI elements otherwise differing only by color
// Changed to use SVG
.status__action-bar-button,
.detailed-status__button .icon-button {
  position: relative;

  &.active {
    &::after {
      position: absolute;
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="#{hex-color($highlight-text-color)}"/></svg>');
      top: -0.55em;
      inset-inline-end: -0.34em;
      width: 6px;
      height: 6px;
    }

    &.star-icon::after {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="#{hex-color($gold-star)}"/></svg>');
    }

    &.bookmark-icon::after {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="#{hex-color($red-bookmark)}"/></svg>');
    }
  }
}

.hicolor-privacy-icons {
  .status__visibility-icon.icon-globe,
  .privacy-dropdown__option .icon-globe {
    color: #1976d2;
  }

  .status__visibility-icon.icon-unlock,
  .privacy-dropdown__option .icon-unlock {
    color: #388e3c;
  }

  .status__visibility-icon.icon-lock,
  .privacy-dropdown__option .icon-lock {
    color: #ffa000;
  }

  .status__visibility-icon.icon-envelope,
  .privacy-dropdown__option .icon-envelope {
    color: #d32f2f;
  }
}
