@use '../variables' as *;

.regeneration-indicator {
  color: $darker-text-color;
  border: 1px solid var(--background-border-color);
  border-top: 0;
  cursor: default;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &__figure {
    display: block;
    width: 100%;
    height: auto;
    max-width: 350px;
    margin-top: -50px;
  }

  &__label {
    text-align: center;
    font-size: 16px;

    strong {
      font-weight: 500;
      display: block;
      margin-bottom: 10px;
      color: $darker-text-color;
    }

    span {
      font-size: 15px;
    }
  }
}
