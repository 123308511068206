@use '../variables' as *;

.dropdown-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background: transparent;
  color: $highlight-text-color;
  border-radius: 6px;
  border: 1px solid $highlight-text-color;
  padding: 4px 8px;
  font-size: 13px;
  line-height: normal;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &[disabled] {
    cursor: default;
    color: $highlight-text-color;
    border-color: $highlight-text-color;
    opacity: 0.5;
  }

  .icon {
    width: 15px;
    height: 15px;
    flex: 0 0 auto;
  }

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1 auto;
  }

  &.active {
    background: $ui-highlight-color;
    border-color: $ui-highlight-color;
    color: $primary-text-color;
  }

  &.warning {
    border-color: var(--goldenrod-2);
    color: var(--goldenrod-2);

    &.active {
      background-color: var(--goldenrod-2);
      color: var(--indigo-1);
    }
  }
}

.dropdown-animation {
  animation: dropdown 250ms cubic-bezier(0.1, 0.7, 0.1, 1);

  @keyframes dropdown {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .reduce-motion & {
    animation: none;
  }
}

.dropdown {
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
}

.dropdown-menu__separator {
  border-bottom: 1px solid var(--dropdown-border-color);
  margin: 2px 0; // glitch: reduced margins
  height: 0;
}

.dropdown-menu {
  background: var(--dropdown-background-color);
  backdrop-filter: var(--background-filter);
  border: 1px solid var(--dropdown-border-color);
  padding: 2px; // glitch: reduced padding
  border-radius: 4px;
  box-shadow: var(--dropdown-shadow);
  z-index: 9999;

  &__text-button {
    display: inline-flex;
    align-items: center;
    color: inherit;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    &:focus-visible {
      outline: 1px dotted;
    }

    &:hover {
      text-decoration: underline;
    }

    .icon {
      width: 12px;
      height: 12px;
    }
  }

  &__container {
    &__header {
      border-bottom: 1px solid var(--dropdown-border-color);
      padding: 6px 14px; // glitch: reduced padding
      padding-bottom: 12px; // glitch: reduced padding
      margin-bottom: 4px;
      font-size: 13px;
      line-height: 18px;
      color: $darker-text-color;
    }

    &__list {
      list-style: none;

      &--scrollable {
        max-height: 300px;
        overflow-y: scroll;
      }
    }

    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 45px;
    }
  }

  &.left {
    transform-origin: 100% 50%;
  }

  &.top {
    transform-origin: 50% 100%;
  }

  &.bottom {
    transform-origin: 50% 0;
  }

  &.right {
    transform-origin: 0 50%;
  }
}

.dropdown-menu__item {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  display: block;

  &--dangerous {
    color: $error-value-color;
  }

  a,
  button {
    font: inherit;
    display: block;
    width: 100%;
    padding: 6px 14px; // glitch: reduced padding
    border: 0;
    margin: 0;
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: inherit;
    border-radius: 4px;

    &:focus,
    &:hover,
    &:active {
      background: var(--dropdown-border-color);
      outline: 0;
    }
  }
}

.privacy-dropdown__dropdown,
.language-dropdown__dropdown {
  box-shadow: var(--dropdown-shadow);
  background: var(--dropdown-background-color);
  backdrop-filter: var(--background-filter);
  border: 1px solid var(--dropdown-border-color);
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  z-index: 2;
  width: 300px;

  &.top {
    transform-origin: 50% 100%;
  }

  &.bottom {
    transform-origin: 50% 0;
  }
}

.privacy-dropdown__option {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  color: $primary-text-color;

  &:hover,
  &:active {
    background: var(--dropdown-border-color);
  }

  &:focus,
  &.active {
    background: $ui-highlight-color;
    color: $primary-text-color;
    outline: 0;

    .privacy-dropdown__option__content,
    .privacy-dropdown__option__content strong,
    .privacy-dropdown__option__additional {
      color: $primary-text-color;
    }
  }

  &__additional {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $darker-text-color;
    cursor: help;
  }
}

.privacy-dropdown__option__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.privacy-dropdown__option__content {
  flex: 1 1 auto;
  color: $darker-text-color;

  strong {
    color: $primary-text-color;
    font-weight: 500;
    display: block;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }
}

.language-dropdown {
  &__dropdown {
    padding: 0;

    .emoji-mart-search {
      padding: 10px;
      background: var(--dropdown-background-color);

      input {
        padding: 8px 12px;
        background: $ui-base-color;
        border: 1px solid var(--background-border-color);
        color: $darker-text-color;

        @media screen and (width <= 600px) {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
        }
      }
    }

    .emoji-mart-search-icon {
      inset-inline-end: 15px;
      opacity: 1;
      color: $darker-text-color;

      .icon {
        width: 18px;
        height: 18px;
      }

      &:disabled {
        opacity: 0.38;
      }
    }

    .emoji-mart-scroll {
      padding: 0 10px 10px;
      background: var(--dropdown-background-color);
    }

    &__results {
      &__item {
        cursor: pointer;
        color: $primary-text-color;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        font-weight: 500;
        padding: 8px 12px;
        border-radius: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &__common-name {
          color: $darker-text-color;
          font-weight: 400;
        }

        &:active,
        &:hover {
          background: var(--dropdown-border-color);
        }

        &:focus,
        &.active {
          background: $ui-highlight-color;
          color: $primary-text-color;
          outline: 0;

          .language-dropdown__dropdown__results__item__common-name {
            color: $primary-text-color;
          }
        }
      }
    }
  }
}
