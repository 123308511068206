@use '../variables' as *;

$ui-header-height: 55px;

@media screen and (width > $mobile-breakpoint) {
  .columns-area {
    padding: 0;
  }

  .column,
  .drawer {
    flex: 0 0 auto;
    padding: 10px;
    padding-inline-start: 5px;
    padding-inline-end: 5px;

    &:first-child {
      padding-inline-start: 10px;
    }

    &:last-child {
      padding-inline-end: 10px;
    }
  }

  .columns-area > div {
    .column,
    .drawer {
      padding-inline-start: 5px;
      padding-inline-end: 5px;
    }
  }
}

.columns-area--mobile {
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .column,
  .drawer {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .account-card {
    margin-bottom: 0;
  }

  .filter-form {
    display: flex;
    flex-wrap: wrap;
  }

  .autosuggest-textarea__textarea {
    font-size: 16px;
  }

  .search__input {
    line-height: 18px;
    font-size: 16px;
    padding: 15px;
    padding-inline-end: 30px;
  }

  .scrollable {
    overflow: visible;

    @supports (display: grid) {
      contain: content;
    }
  }

  @media screen and (min-width: $no-gap-breakpoint) {
    padding: 10px 0;
    padding-top: 0;
  }
}

@media screen and (min-width: $no-gap-breakpoint) {
  .react-swipeable-view-container .columns-area--mobile {
    height: calc(100% - 10px) !important;
  }

  .getting-started__wrapper {
    margin-bottom: 10px;
  }

  .search-page .search {
    display: none;
  }

  .navigation-panel__legal {
    display: none;
  }
}

@media screen and (max-width: $no-gap-breakpoint - 1px) {
  $sidebar-width: 285px;

  .columns-area__panels__main {
    width: calc(100% - $sidebar-width);
  }

  .columns-area__panels {
    min-height: calc(100vh - $ui-header-height);
    gap: 0;
  }

  .columns-area__panels__pane--navigational {
    min-width: $sidebar-width;

    .columns-area__panels__pane__inner {
      width: $sidebar-width;
    }

    .navigation-panel {
      margin: 0;
      border-inline-start: 1px solid var(--background-border-color);
      height: 100vh;
    }

    .navigation-panel__sign-in-banner,
    .navigation-panel__logo,
    .navigation-panel__banner,
    .getting-started__trends {
      display: none;
    }

    .column-link__icon {
      font-size: 18px;
    }
  }

  .layout-single-column {
    .ui__header {
      display: flex;
      background: var(--background-color);
      border-bottom: 1px solid var(--background-border-color);
    }

    .column > .scrollable,
    .tabs-bar__wrapper .column-header,
    .tabs-bar__wrapper .column-back-button,
    .explore__search-header,
    .column-search-header {
      border-left: 0;
      border-right: 0;
    }

    .column-header,
    .column-back-button,
    .scrollable,
    .error-column {
      border-radius: 0 !important;
    }

    .column-header,
    .column-back-button {
      border-top: 0;
    }
  }
}

@media screen and (max-width: $no-gap-breakpoint - 285px - 1px) {
  $sidebar-width: 55px;

  .columns-area__panels__main {
    width: calc(100% - $sidebar-width);
  }

  .columns-area__panels__pane--navigational {
    min-width: $sidebar-width;

    .columns-area__panels__pane__inner {
      width: $sidebar-width;
    }

    .column-link span {
      display: none;
    }

    .list-panel {
      display: none;
    }
  }
}

@media screen and (max-width: $no-gap-breakpoint - 1px) {
  .columns-area__panels__pane--compositional {
    display: none;
  }
}

.compose-panel {
  width: 285px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
  overflow-y: hidden;

  .compose-form {
    flex: 1 1 auto;
    min-height: 0;
  }
}

.navigation-panel {
  margin-top: 10px;
  margin-bottom: 10px;
  height: calc(100% - 20px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__menu {
    flex: 1 1 auto;
    min-height: 0;
    overflow-y: auto;
  }

  .logo {
    height: 30px;
    width: auto;
  }

  &__logo {
    margin-bottom: 12px;
  }

  @media screen and (height <= 710px) {
    &__portal {
      display: none;
    }
  }

  @media screen and (height <= 765px) {
    &__portal .trends__item:nth-child(n + 3) {
      display: none;
    }
  }

  @media screen and (height <= 820px) {
    &__portal .trends__item:nth-child(n + 4) {
      display: none;
    }
  }

  @media screen and (height <= 920px) {
    .column-link.column-link--optional {
      display: none;
    }
  }

  @media screen and (height <= 1040px) {
    .list-panel {
      display: none;
    }
  }
}

.navigation-panel,
.compose-panel {
  hr {
    flex: 0 0 auto;
    border: 0;
    background: transparent;
    border-top: 1px solid var(--background-border-color);
    margin: 10px 0;
  }

  .flex-spacer {
    background: transparent;
  }
}
