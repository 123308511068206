@use '../../variables' as *;
@use '../../functions' as *;
@use '../mixins' as *;

.compose-form {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .layout-multiple-columns &,
  .column & {
    padding: 15px;
  }

  &__highlightable {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 0 1 auto;
    border-radius: 4px;
    border: 1px solid var(--background-border-color);
    transition: border-color 300ms linear;
    min-height: 0;
    position: relative;
    background: var(--input-background-color);
    overflow-y: auto;

    &.active {
      transition: none;
      border-color: $ui-highlight-color;
    }
  }

  &__warning {
    color: $inverted-text-color;
    background: $ui-primary-color;
    box-shadow: 0 2px 6px rgba($base-shadow-color, 0.3);
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;

    strong {
      color: $inverted-text-color;
      font-weight: 500;

      @each $lang in $cjk-langs {
        &:lang(#{$lang}) {
          font-weight: 700;
        }
      }
    }

    a {
      color: $lighter-text-color;
      font-weight: 500;
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .spoiler-input {
    display: flex;
    align-items: stretch;

    &__border {
      // Polyam: Don't show stripes. Keep same as input
      background: rgba($ui-highlight-color, 0.05);
      width: 5px;
      flex: 0 0 auto;

      &:first-child {
        border-start-start-radius: 4px;
      }

      &:last-child {
        border-start-end-radius: 4px;
      }
    }

    .autosuggest-input {
      flex: 1 1 auto;
      border-bottom: 1px solid var(--background-border-color);
    }
  }

  .autosuggest-textarea__textarea,
  .spoiler-input__input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    color: $secondary-text-color;
    background: var(--input-background-color);
    font-family: inherit;
    font-size: 14px;
    padding: 12px;
    line-height: normal;
    border: 0;
    outline: 0;

    &:focus {
      outline: 0;
    }
  }

  .spoiler-input__input {
    padding: 12px 12px - 5px;
    background: rgba($ui-highlight-color, 0.05);
    color: $highlight-text-color;
  }

  &__dropdowns {
    display: flex;
    align-items: center;
    gap: 8px;

    & > div {
      overflow: hidden;
      display: flex;
    }
  }

  &__uploads {
    padding: 0 12px;
    aspect-ratio: 3/2;
  }

  .media-gallery {
    gap: 8px;
  }

  &__upload {
    position: relative;
    cursor: grab;

    &.dragging {
      opacity: 0;
    }

    &.overlay {
      height: 100%;
      border-radius: 8px;
      pointer-events: none;
    }

    &__drag-handle {
      position: absolute;
      top: 50%;
      inset-inline-start: 0;
      transform: translateY(-50%);
      color: $white;
      background: transparent;
      border: 0;
      padding: 8px 3px;
      cursor: grab;
    }

    &__actions {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 8px;
    }

    &__preview {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      z-index: -1;
      top: 0;
      inset-inline-start: 0;
    }

    &__thumbnail {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;
    }

    .icon-button {
      flex: 0 0 auto;
      color: $white;
      background: rgba(0, 0, 0, 75%);
      border-radius: 6px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      padding: 4px 8px;
      font-family: inherit;

      .icon {
        width: 15px;
        height: 15px;
      }
    }

    // Polyam: Kept padding of 3px
    .icon-button.compose-form__upload__delete {
      padding: 3px;
      border-radius: 50%;

      // Polyam: Kept size at 18px
      .icon {
        width: 18px;
        height: 18px;
      }
    }

    &__warning {
      position: absolute;
      z-index: 2;
      bottom: 0;
      inset-inline-start: 0;
      inset-inline-end: 0;
      padding: 8px;

      .icon-button.active {
        color: #ffbe2e;
        background: rgba(0, 0, 0, 75%);
      }
    }
  }

  // glitch: reintroduce sensitive button
  &__sensitive-button {
    padding: 0 12px;

    input[type='checkbox'] {
      appearance: none;
      display: block;
      border: 1px solid $ui-primary-color;
      box-sizing: border-box;
      width: 17px;
      height: 17px;
      border-radius: 4px;
      flex: 0 0 auto;

      &:active,
      &:focus,
      &:hover {
        border-color: $highlight-text-color;
        border-width: 4px;
      }

      &:checked {
        background-color: $highlight-text-color;
        border-color: $highlight-text-color;
      }

      &::-moz-focus-inner {
        outline: 0 !important;
        border: 0;
      }

      &:focus,
      &:active {
        outline: 0 !important;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    padding-top: 0;
  }

  &__submit {
    display: flex;
    flex: 1 0 100%; // glitch: always on its own line
    max-width: 100%;
    overflow: hidden;
    gap: 5px; // glitch: handle secondary post privacy
    align-items: stretch; // glitch: handle secondary post privacy

    .button.secondary-post-button {
      flex: 0 1 auto;
      padding-top: 0;
      padding-bottom: 0;

      .icon {
        vertical-align: middle;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 5px; // Polyam: Reduced gap to fit icons
    align-items: center;
    flex: 1 1 auto;

    & > div {
      display: flex;
    }

    // Polyam: Kept padding of 3px
    .icon-button {
      padding: 3px;
    }

    // Polyam: Kept size at 18px
    .icon-button .icon {
      width: 18px;
      height: 18px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    gap: 12px;
    flex-wrap: wrap;

    @include compose-action-button;

    .icon-button {
      box-sizing: content-box;
      color: $highlight-text-color;

      &:hover,
      &:focus,
      &:active {
        color: $highlight-text-color;
      }

      &.disabled {
        color: $highlight-text-color;
        opacity: 0.5;
      }

      &.active {
        background: $ui-highlight-color;
        color: $primary-text-color;
      }
    }
  }

  &__poll {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 8px;

    .poll__option {
      padding: 0 12px;
      gap: 8px;

      &.empty:not(:focus-within) {
        opacity: 0.5;
      }
    }

    .poll__input {
      width: 17px;
      height: 17px;
      border-color: $darker-text-color;
    }

    &__footer {
      display: flex;
      align-items: center;
      gap: 16px;
      padding-inline-start: 37px;
      padding-inline-end: 40px;

      &__sep {
        width: 1px;
        height: 22px;
        background: lighten($ui-base-color, 8%);
        flex: 0 0 auto;
      }
    }

    &__select {
      display: flex;
      flex-direction: column;
      gap: 2px;
      flex: 1 1 auto;
      min-width: 0;

      &__label {
        flex: 0 0 auto;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: $darker-text-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__value {
        flex: 0 0 auto;
        appearance: none;
        background: transparent;
        border: none;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $highlight-text-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.character-counter {
  cursor: default;
  font-family: $font-sans-serif, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: $darker-text-color;
  flex: 1 0 auto;
  text-align: end;

  &.character-counter--over {
    color: $error-red;
  }
}

.no-reduce-motion .spoiler-input {
  transition:
    height 0.4s ease,
    opacity 0.4s ease;
}

// Polyam: Split out from .status__content, .edit-indicator__content, .reply-indicator__content upstream
.edit-indicator__content,
.reply-indicator__content {
  @include status-content;
}

.reply-indicator {
  display: grid;
  grid-template-columns: 46px minmax(0, 1fr);
  grid-template-rows: 46px max-content;
  gap: 0 10px;

  .detailed-status__display-name {
    margin-bottom: 4px;
  }

  .detailed-status__display-avatar {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: span 1;
  }

  &__main {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: span 2;
  }

  .display-name {
    font-size: 14px;
    line-height: 16px;

    &__account {
      display: none;
    }
  }

  &__line {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: span 1;
    position: relative;

    &::before {
      display: block;
      content: '';
      position: absolute;
      inset-inline-start: 50%;
      top: 4px;
      transform: translateX(-50%);
      background: lighten($ui-base-color, 8%);
      width: 2px;
      height: calc(100% + 32px - 8px); // Account for gap to next element
    }
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    padding: 0;
    max-height: 4 * 20px;
    overflow: hidden;
    color: $darker-text-color;
  }

  &__attachments {
    margin-top: 4px;
    color: $darker-text-color;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 4px;

    .icon {
      width: 18px;
      height: 18px;
    }
  }
}

.edit-indicator {
  border-radius: 4px 4px 0 0;
  background: lighten($ui-base-color, 4%);
  padding: 12px;
  overflow-y: auto;
  flex: 0 0 auto;
  border-bottom: 0.5px solid lighten($ui-base-color, 8%);
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $darker-text-color;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__cancel {
    display: flex;

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  &__display-name {
    display: flex;
    gap: 4px;

    a {
      color: inherit;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__content {
    color: $secondary-text-color;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding-top: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 4 * 20px;
    overflow: hidden;

    a {
      color: $highlight-text-color;
    }
  }

  &__attachments {
    color: $darker-text-color;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.75;
    display: flex;
    align-items: center;
    gap: 4px;

    .icon {
      width: 18px;
      height: 18px;
    }
  }
}

.edit-indicator__content,
.reply-indicator__content {
  .emojione {
    width: 18px;
    height: 18px;
    margin: -3px 0 0;
  }
}
