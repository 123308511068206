@use '../variables' as *;
@use '../functions' as *;

.inline-follow-suggestions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
  padding-bottom: 0;
  border-bottom: 1px solid var(--background-border-color);
  background: rgba($ui-highlight-color, 0.05);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    h3 {
      font-size: 15px;
      line-height: 22px;
      font-weight: 500;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .link-button {
      font-size: 13px;
      font-weight: 500;
    }
  }

  &__body {
    position: relative;

    &__scroll-button {
      position: absolute;
      height: 100%;
      background: transparent;
      border: none;
      cursor: pointer;
      top: 0;
      color: $primary-text-color;
      opacity: 0.5;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }

      &__icon {
        border-radius: 50%;
        background: $ui-highlight-color;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;
        padding: 8px;

        .icon {
          width: 24px;
          height: 24px;
        }
      }

      &:hover,
      &:focus,
      &:active {
        opacity: 1;

        .inline-follow-suggestions__body__scroll-button__icon {
          background: lighten($ui-highlight-color, 4%);
        }
      }
    }

    &__scrollable {
      display: flex;
      flex-wrap: nowrap;
      gap: 16px;
      padding: 16px;
      scroll-snap-type: x mandatory;
      scroll-padding: 16px;
      scroll-behavior: smooth;
      overflow-x: scroll;
      scrollbar-width: none;

      &__card {
        background: var(--background-color);
        border: 1px solid var(--background-border-color);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        padding: 12px;
        scroll-snap-align: start;
        flex: 0 0 auto;
        width: 200px;
        box-sizing: border-box;
        position: relative;

        a {
          text-decoration: none;
        }

        & > .icon-button {
          position: absolute;
          inset-inline-end: 8px;
          top: 8px;
          opacity: 0.75;
        }

        &__avatar {
          height: 48px;
          display: flex;

          a {
            display: flex;
            text-decoration: none;
          }
        }

        .account__avatar {
          flex-shrink: 0;
          align-self: flex-end;
          border: 1px solid var(--background-border-color);
          background-color: $ui-base-color;
        }

        &__text-stack {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          max-width: 100%;

          a {
            max-width: 100%;
          }

          &__source {
            display: inline-flex;
            align-items: center;
            color: $dark-text-color;
            gap: 4px;
            overflow: hidden;
            white-space: nowrap;
            cursor: help;

            > span {
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .icon {
              width: 16px;
              height: 16px;
            }
          }
        }

        .display-name {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;

          & > * {
            max-width: 100%;
          }

          &__html {
            font-size: 15px;
            font-weight: 500;
            color: $secondary-text-color;
          }

          &__account {
            font-size: 14px;
            color: $darker-text-color;
          }
        }

        .verified-badge {
          font-size: 14px;
          max-width: 100%;
        }

        .button {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
